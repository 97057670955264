import {autoinject, TaskQueue} from 'aurelia-framework';
import {DialogController, DialogService} from 'aurelia-dialog';
import {FormBaseClass} from "./FormBaseClass";
import {I18N} from "aurelia-i18n";
import {Router} from "aurelia-router";
import {PatientChangeNotifier} from "../services/PatientChangeNotifier";
import {AnalyzeService} from "../services/analyzeService";
import {PatientService} from "../services/PatientService";
import {DialogMessages} from "../services/DialogMessages";
import {qChangeNotifier} from "./questionnaire/q-changeNotifier";
import {IdLogikService} from "../services/IdLogikService";
import {PermissionService} from 'resources/services/PermissionService';
import {LogoutService} from "../services/LogoutService";

@autoinject
export class ModalQuestionnaireDefault extends FormBaseClass {
    element;
    controller;


    constructor(protected i18n: I18N,
                router: Router, queue: TaskQueue, notifier: PatientChangeNotifier, dialogService: DialogService,
                analyzeService: AnalyzeService,
                protected patientService: PatientService,
                protected dialogMessages: DialogMessages,
                protected idLogikService: IdLogikService,
                protected responseNotifier: qChangeNotifier,
                protected permissionService: PermissionService,
                dialogController: DialogController,
                element: Element,
                logoutService : LogoutService) {
        super(i18n, router, queue, notifier, dialogService, analyzeService, patientService, dialogMessages, idLogikService, responseNotifier, permissionService, logoutService);
        this.element = element;
        this.controller = dialogController;
    }

    async activate(params) {
        await super.activate(params);
        this.questionnaireName = params.name;
        this.showSaveButton = false;
        this.showResponseSelect = false;
        this.showSettings = false;
        this.showButtonComplete = false;
        if (!this._buttonText) this._buttonText = "add_document";
    }

    async attached() {
        await super.attached();
        this.element.style.maxWidth = "660px";
        this.element.style.width = "60%";
    }

    submit() {
        this.controller.ok(null);
    }

    close() {
        this.controller.close();
    }
}

