import {autoinject} from 'aurelia-framework';
import {translations} from "../classes/translations";
import {DialogController, DialogService} from "aurelia-dialog";
import {FhirService} from "../services/FhirService";
import {UserService} from "../services/UserService";
import {RuntimeInfo} from "../classes/RuntimeInfo";
import {fhirEnums} from "../classes/fhir-enums";
import ResourceType = fhirEnums.ResourceType;

const moment = require("moment");

@autoinject
export class ModalMedicationAddDiagnostic {
    controller;
    dialogService;
    _fhirService : FhirService;
    get fhirService() {
        if (!this._fhirService)
            this._fhirService = new FhirService();
        
        return this._fhirService;
    }
    
    userService;

    dpOptions = {
        format: RuntimeInfo.DateTimeFormat,
        locale: translations.language,
        showClear: false,
        showClose: true,
        widgetPositioning: {
            horizontal: 'left',
            vertical: 'auto'
        },
        focusOnShow: false,
        keepInvalid: false
    };

    patient;

    dateObjectStart: Date;
    dateValueStart: string;
    dateElementStart;
    dateObjectEnd: Date;
    dateValueEnd: string;
    dateElementEnd;

    catalog = [];
    item = null;
    type = 'regular';
    morningDose = 0;
    noonDose = 0;
    afternoonDose = 0;
    nightDose = 0;
    unit = '';
    route = '';
    comment = '';
    practitioner;

    get isFormValid() {
        return this.dateValueStart && this.item && (this.morningDose || this.noonDose || this.afternoonDose || this.nightDose) && this.unit && this.route;
    }

    constructor(dialogController: DialogController, dialogService: DialogService, userService: UserService) {
        this.controller = dialogController;
        this.dialogService = dialogService;
        this.userService = userService;

        this.controller.settings.centerHorizontalOnly = true;
    }

    async activate(data) {
        this.patient = data.patient;

        if (this.userService.practitioner) {
            this.practitioner = this.userService.fullNameOrUsername;
        }

        const medications = await this.fhirService.fetch(`${fhirEnums.ResourceType.medication}`);

        this.catalog = medications.filter(medication => medication.code && medication.code.coding).map((medication) => {
            const display = [];

            if (medication.code && medication.code.coding[0]) {
                display.push(medication.code.coding[0].display);
            }

            if (medication.code && medication.code.coding[1]) {
                display.push(medication.code.coding[1].display);
            }

            return {
                display: display.join(' / '),
                medication
            };
        });
    }

    submit() {
        if (!this.isFormValid) {
            return;
        }

        const medicationRequest: any = {
            resourceType: ResourceType.medicationRequest,
            authoredOn: moment().toJSON(),
            status: 'active',
            intent: 'plan',
            context: {reference: `${ResourceType.encounter}/${this.patient.encounterId}`},
            subject: {reference: `${ResourceType.patient}/${this.patient.id}`},
            category: {
                text: this.type,
            },
            dispenseRequest: {
                validityPeriod: {
                    start: moment(this.dateObjectStart).toJSON()
                }
            },
            dosageInstruction: [],
            note: [{text: this.comment}],
            medicationReference: {
                reference: `${ResourceType.medication}/${this.item.medication.id}`,
                display: this.item.display
            },
            requester: {agent: {}}
        };

        if (this.userService.practitioner) {
            medicationRequest.requester.agent = {reference: "Practitioner/" + this.userService.practitioner.id};
        }

        if (this.dateValueEnd) {
            medicationRequest.dispenseRequest.validityPeriod.end = moment(this.dateObjectEnd).toJSON();
        }

        const schedule = [0, 0, 0, 0];

        if (this.morningDose > 0) {
            medicationRequest.dosageInstruction.push(this.dosageInstruction(this.morningDose, "08:00"));
            schedule[0] = this.morningDose;
        }

        if (this.noonDose > 0) {
            medicationRequest.dosageInstruction.push(this.dosageInstruction(this.noonDose, "12:00"));
            schedule[1] = this.noonDose;
        }

        if (this.afternoonDose > 0) {
            medicationRequest.dosageInstruction.push(this.dosageInstruction(this.afternoonDose, "18:00"));
            schedule[2] = this.afternoonDose;
        }

        if (this.nightDose > 0) {
            medicationRequest.dosageInstruction.push(this.dosageInstruction(this.nightDose, "22:00"));
            schedule[3] = this.nightDose;
        }

        const scheduleText = `${schedule.join('-')} ${this.unit}`;

        medicationRequest.dosageInstruction.forEach((dosageInstruction) => {
            dosageInstruction.text = scheduleText;
        });

        this.controller.ok(medicationRequest);
    }

    dosageInstruction(dose, time) : any {
        return {
            doseQuantity: {
                value: parseInt(dose, 10),
                unit: this.unit
            },
            timing: {
                repeat: {
                    frequency: 1,
                    period: 1,
                    periodUnit: "d",
                    timeOfDay: [time]
                }
            },
            route: {
                coding: [{
                    system: 'http://nursit-institute.com/fhir/StructureDefinition/medication-route',
                    code: this.route
                }]
            }
        };
    }

    close() {
        this.controller.cancel();
    }
}
