import {inject, TaskQueue} from 'aurelia-framework';
import {DialogController, DialogService} from 'aurelia-dialog';
import {translations} from "../classes/translations";

const moment = require("moment");
import {LOINC} from "../classes/Codes";
import {UserService} from "../services/UserService";
import {FhirService} from "../services/FhirService";
import {fhirEnums} from "../classes/fhir-enums";
import {ConfigService} from "../services/ConfigService";
import {RuntimeInfo} from "../classes/RuntimeInfo";

@inject(Element, DialogController, DialogService, TaskQueue, UserService, FhirService)
export class ModalTodoListBulkProceduresEdit {
    element: HTMLElement;
    controller: DialogController;
    dialogService: DialogService;
    taskQueue: TaskQueue;
    userService: UserService;

    dpOptions = {
        format: 'DD.MM.YYYY HH:mm',
        locale: translations.language,
        showClear: false,
        showClose: true,
        widgetPositioning: {
            horizontal: 'left',
            vertical: 'auto'
        },
        focusOnShow: false,
        keepInvalid: false
    };

    patient;
    fhirService: FhirService;

    procedures = [];

    constructor(element, dialogController, dialogService, taskQueue, userService, fhirService: FhirService) {
        this.element = element;
        this.controller = dialogController;
        this.dialogService = dialogService;
        this.taskQueue = taskQueue;
        this.userService = userService;
        this.fhirService = fhirService;

        this.controller.settings.centerHorizontalOnly = true;
    }

    attached() {
        this.element.style.maxWidth = "1150px";
    }

    activate(data) {
        this.patient = data.patient;

        this.procedures = data.bulk.map((bulk) => {
            const procedure = bulk.procedure.resource;
            const performedPeriodStart = moment(procedure.performedPeriod.start);
            const performedPeriodEnd = moment(procedure.performedPeriod.end);
            const duration = moment.duration(performedPeriodEnd.diff(performedPeriodStart));
            const notDone = procedure.hasOwnProperty('notDone') ? procedure.notDone : false;
            const comment = procedure.note && procedure.note[0].text || '';
            let currentStatus;

            if (procedure.status === fhirEnums.EventStatus.preparation) {
                currentStatus = 'done';
            } else if (procedure.status === fhirEnums.EventStatus.completed) {
                if (!notDone) {
                    currentStatus = 'done';
                } else {
                    currentStatus = 'not_done';
                }
            } else if (procedure.status === 'not-done') {
                currentStatus = 'not_done';
            } else if (procedure.status === fhirEnums.EventStatus.aborted || procedure.status === 'stopped') {
                currentStatus = 'canceled';
            } else if (procedure.status === fhirEnums.EventStatus.enteredInError) {
                currentStatus = 'entered-in-error';
            }

            const reasonData = this.getReason(procedure);
            const isReadOnly = bulk.procedure.procedureRequest ? moment(procedure.performedPeriod.start).isBefore(moment().startOf('day')) && !bulk.procedure.procedureRequest.isActive : false;

            return {
                id: procedure.id,
                isLocked: isReadOnly || procedure.status !== fhirEnums.EventStatus.preparation,
                shiftReport: procedure.hasOwnProperty('followUp'),
                duration: duration.asMinutes(),
                status: currentStatus,
                comment: comment,
                dateObject: performedPeriodStart.toDate(),
                dateValue: performedPeriodStart.format(this.dpOptions.format),
                performedPeriod: procedure.performedPeriod,
                reason: reasonData.reason,
                otherReason: reasonData.otherReason,
                dtPicker: null,
                resource: procedure,
                resourceIndex: bulk.procedureIndex,
                request: bulk.request,
                procedureRequest: procedure.procedureRequest,
                isReadOnly: isReadOnly,
            };
        });
    }

    getReason(procedure) {
        const reason = {
            reason: '',
            otherReason: ''
        };

        if (FhirService.FhirVersion > 3) {
            if (procedure.statusReason) {
                reason.otherReason = procedure.statusReason.text;

                if (procedure.statusReason.coding) {
                    const reasonCoding = procedure.statusReason.coding.find((c) => c.system === RuntimeInfo.SystemHeader + '/procedure-not-done-reason');

                    if (reasonCoding) {
                        reason.reason = reasonCoding.code;
                    }
                }
            }
        } else {
            if (procedure.notDoneReason) {
                reason.otherReason = procedure.notDoneReason.text;

                if (procedure.notDoneReason.coding) {
                    const reasonCoding = procedure.notDoneReason.coding.find((c) => c.system === RuntimeInfo.SystemHeader + '/procedure-not-done-reason');

                    if (reasonCoding) {
                        reason.reason = reasonCoding.code;
                    }
                }
            }
        }

        return reason;
    }

    toggleLocked(procedure) {
        if (!procedure.isReadOnly) {
            procedure.isLocked = !procedure.isLocked;
        }
    }

    changeStatus(idx, status) {
        this.procedures[idx].status = status;
    }

    changeStatusAll(status) {
        this.procedures.filter((procedure) => !procedure.isReadOnly).forEach((procedure) => {
            if (!procedure.isLocked) {
                procedure.status = status;
            }
        });
    }

    submit() {
        Promise.all(this.procedures.filter((procedure) => !procedure.isReadOnly).map((procedure) => {
            return this.saveProcedure(procedure);
        })).then(() => {
            this.controller.ok();
        });
    }

    async saveProcedure(procedure) {
        let shiftReportId = null;

        switch (procedure.status) {
            case ProcedureStatus.Done: {
                procedure.resource.status = fhirEnums.EventStatus.completed;
                procedure.resource.notDone = false;
                procedure.resource.note = [{text: procedure.comment}];
                procedure.resource.performedPeriod.start = procedure.dateObject;
                procedure.resource.performedPeriod.end = moment(procedure.dateObject).add(procedure.duration, 'minutes');

                delete procedure.resource.notDoneReason;
                delete procedure.reason.statusReason;

                break;
            }
            case ProcedureStatus.NotDone: {
                if (FhirService.FhirVersion > 3) {
                    procedure.resource.status = 'not-done';
                    procedure.resource.statusReason = {
                        coding: [{
                            system: RuntimeInfo.SystemHeader + '/procedure-not-done-reason',
                            code: procedure.reason
                        }],
                        text: procedure.reason == 'other' ? procedure.otherReason : null
                    };
                } else {
                    procedure.resource.status = fhirEnums.EventStatus.completed;
                    procedure.resource.notDone = true;
                    procedure.resource.notDoneReason = {
                        coding: [{
                            system: RuntimeInfo.SystemHeader + '/procedure-not-done-reason',
                            code: procedure.reason
                        }],
                        text: procedure.reason == 'other' ? procedure.otherReason : null
                    };
                }
                break;
            }
            case ProcedureStatus.Canceled: {
                if (FhirService.FhirVersion > 3) {
                    procedure.resource.status = 'stopped';
                    procedure.resource.statusReason = {
                        coding: [{
                            system: RuntimeInfo.SystemHeader + '/procedure-not-done-reason',
                            code: procedure.reason
                        }],
                        text: procedure.reason == 'other' ? procedure.otherReason : null
                    };
                } else {
                    procedure.resource.status = fhirEnums.EventStatus.aborted;
                    procedure.resource.notDone = true;
                    procedure.resource.notDoneReason = {
                        coding: [{
                            system: RuntimeInfo.SystemHeader + '/procedure-not-done-reason',
                            code: procedure.reason
                        }],
                        text: procedure.reason == 'other' ? procedure.otherReason : null
                    };
                }
                break;
            }
            case ProcedureStatus.EnteredInError: {
                procedure.resource.status = fhirEnums.EventStatus.enteredInError;
                procedure.shiftReport = false;
                break;
            }
        }

        if (this.userService.practitioner) {
            procedure.resource.performer = [{
                actor: {
                    reference: `Practitioner/${this.userService.practitioner.id}`,
                    display: this.userService.fullNameOrUsername
                }
            }];
        }

        if (procedure.shiftReport) {
            if (!procedure.resource.hasOwnProperty('followUp')) {
                const report: any = {
                    resourceType: fhirEnums.ResourceType.observation,
                    subject: {reference: `${fhirEnums.ResourceType.patient}/${this.patient.id}`},
                    [FhirService.FhirVersion > 3 ? 'encounter' : 'context']: {reference: `${fhirEnums.ResourceType.encounter}/${this.patient.encounterId}`},
                    status: 'registered',
                    code: {
                        coding: [{
                            system: LOINC.SYSTEM,
                            code: LOINC.CODES.REPORT.code
                        }]
                    },
                    effectiveDateTime: procedure.dateObject,
                    valueString: translations.translate(translations.translate(procedure.status == 'done' ? 'done' : (procedure.status == 'notDone' ? 'not_done' : procedure.status))) + ' um ' + moment(procedure.dateObject).format('DD.MM.YYYY HH:mm') + '. ' + procedure.request.codeSystem.display + '. ' + procedure.comment,
                    component: [{
                        code: {
                            text: 'shift'
                        },
                        valueString: 'day'
                    }, {
                        code: {
                            text: 'mark-supplement'
                        },
                        valueString: 'false'
                    }, {
                        code: {
                            text: 'nursing-transfer'
                        },
                        valueString: 'false'
                    },
                        {
                            code: {
                                text: 'additional-info'
                            },
                            valueString: 'false'
                        }]
                };

                if (this.userService.practitioner) {
                    report.performer = [{
                        reference: `Practitioner/${this.userService.practitioner.id}`,
                        display: this.userService.fullNameOrUsername
                    }];
                }

                procedure.resource.followUp = {
                    text: 'shift_report'
                };

                const result = await this.fhirService.create(report);

                procedure.resource.partOf = {reference: `${fhirEnums.ResourceType.observation}/${result.id}`};
            }
        } else {
            if (procedure.resource.hasOwnProperty('followUp') && procedure.resource.hasOwnProperty('partOf')) {
                shiftReportId = procedure.resource.partOf[0].reference.split('/')[1];

                delete procedure.resource.partOf;
                delete procedure.resource.followUp;
            }
        }

        procedure.request.procedures[procedure.resourceIndex].resource = await this.fhirService.update(procedure.resource);

        if (shiftReportId) {
            return this.deleteShiftReport(shiftReportId);
        }
    }

    deleteShiftReport(id) {
        return this.fhirService.delete({
            id,
            resourceType: fhirEnums.ResourceType.observation
        });
    }

    close() {
        this.controller.cancel();
    }
}

enum ProcedureStatus {
    Done = 'done',
    NotDone = 'not_done',
    Canceled = 'canceled',
    EnteredInError = 'entered-in-error'
}
