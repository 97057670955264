import {inject, TaskQueue} from 'aurelia-framework';
import {DialogController, DialogService} from 'aurelia-dialog';
import {translations} from "../classes/translations";

const moment = require("moment");
import {LOINC} from "../classes/Codes";
import {UserService} from "../services/UserService";
import {FhirService} from "../services/FhirService";
import {fhirEnums} from "../classes/fhir-enums";
import {RuntimeInfo} from "../classes/RuntimeInfo";

@inject(Element, DialogController, DialogService, TaskQueue, UserService)
export class ModalMedicationBulk {
    element: HTMLElement;
    controller: DialogController;
    dialogService: DialogService;
    taskQueue: TaskQueue;
    userService: UserService;
    _fhirService: FhirService;
    get fhirService(): FhirService {
        if (!this._fhirService)
            this._fhirService = new FhirService();
        
        return this._fhirService;
    }
    
    dpOptions = {
        format: 'HH:mm',
        locale: translations.language,
        showClear: false,
        showClose: true,
        widgetPositioning: {
            horizontal: 'left',
            vertical: 'auto'
        },
        focusOnShow: false,
        keepInvalid: false
    };

    patient;
    currentDay;

    administrations = [];

    constructor(element, dialogController, dialogService, taskQueue, userService) {
        this.element = element;
        this.controller = dialogController;
        this.dialogService = dialogService;
        this.taskQueue = taskQueue;
        this.userService = userService;

        this.controller.settings.centerHorizontalOnly = true;
    }

    attached() {
        this.element.style.maxWidth = "1150px";
    }

    activate(data) {
        this.currentDay = data.currentDay;
        this.patient = data.patient;

        this.administrations = data.administrations.map((administration) => {
            let reason, otherReason;

            if (administration.resource.notGiven) {
                const reasonNotGiven = administration.resource.reasonNotGiven[0];

                reason = reasonNotGiven.coding[0].code;
                otherReason = reason == 'other' ? reasonNotGiven.text : null;
            }
            return Object.assign({
                isLocked: administration.status === 'administered' || administration.status === 'not-administered',
                dtPicker: null,
                dateValue: null,
                dateObject: moment(administration.resource.effectiveDateTime).toDate(),
                comment: administration.resource.note && administration.resource.note[0].text || '',
                shiftReport: Boolean(administration.resource.supportingInformation),
                reason,
                otherReason
            }, administration);
        }).sort((a, b) => {
            const aStart = moment(a.resource.effectiveDateTime);
            const bStart = moment(b.resource.effectiveDateTime);

            if (a.request.name > b.request.name) return 1;
            if (a.request.name < b.request.name) return -1;
            if (aStart.isAfter(bStart)) return 1;
            else if (bStart.isAfter(aStart)) return -1;

            return 0;
        });
    }

    changeStatus(idx, status) {
        this.administrations[idx].status = status;
    }

    changeStatusAll(status) {
        this.administrations.forEach((administration) => {
            if (!administration.isLocked) {
                administration.status = status;
            }
        });
    }

    submit() {
        Promise.all(this.administrations.map((administration) => {
            return this.processAdministration(administration);
        })).then((administrations) => {
            this.controller.ok(administrations);
        });
    }

    processAdministration(administration) {
        const dateObject = moment(administration.dateObject);
        const date = moment(this.currentDay).add(dateObject.hours(), 'hours').add(dateObject.minutes(), 'minutes');

        administration.resource.effectiveDateTime = date.toJSON();

        if (administration.status === 'not-administered') {
            administration.resource.notGiven = true;
            administration.resource.reasonNotGiven = [{
                coding: [{
                    system: RuntimeInfo.SystemHeader + '/medication-not-given-reason',
                    code: administration.reason
                }],
                text: administration.reason == 'other' ? administration.otherReason : null
            }];

            delete administration.resource.note;
        } else {
            delete administration.resource.notGiven;
            delete administration.resource.reasonNotGiven;

            administration.resource.note = [{text: administration.comment}];
        }

        switch (administration.status) {
            case 'open':
            case 'placed':
            case 'controlled':
            case 'distributed': {
                administration.resource.status = 'in-progress';
                administration.resource.reasonCode = [{
                    coding: [{
                        system: RuntimeInfo.SystemHeader + '/medication-status',
                        code: administration.status
                    }]
                }];
                break;
            }
            case 'administered': {
                administration.resource.status = 'completed';
                delete administration.resource.reasonCode;
                break;
            }
            case 'not-administered': {
                administration.resource.status = 'stopped';
                delete administration.resource.reasonCode;
                break;
            }
        }

        if (administration.shiftReport) {
            if (!administration.resource.supportingInformation) {
                const report = {
                    resourceType: fhirEnums.ResourceType.observation,
                    subject: {reference: `${fhirEnums.ResourceType.patient}/${this.patient.id}`},
                    context: {reference: `${fhirEnums.ResourceType.encounter}/${this.patient.encounterId}`},
                    status: 'registered',
                    code: {
                        coding: [{
                            system: LOINC.SYSTEM,
                            code: LOINC.CODES.REPORT.code
                        }]
                    },
                    effectiveDateTime: administration.dateObject,
                    valueString: `${translations.translate(administration.status)} um ${moment(administration.dateObject).format('DD.MM.YYYY HH:mm')}. ${administration.request.name} ${administration.request.schedule} ${administration.comment}`,
                    comment: this.userService.username,
                    component: [{
                        code: {
                            text: 'shift'
                        },
                        valueString: 'day'
                    }, {
                        code: {
                            text: 'mark-supplement'
                        },
                        valueString: 'false'
                    }, {
                        code: {
                            text: 'nursing-transfer'
                        },
                        valueString: 'false'
                    },
                        {
                            code: {
                                text: 'additional-info'
                            },
                            valueString: 'false'
                        }]
                };

                return this.fhirService.create(report).then((result) => {
                    administration.resource.supportingInformation = {reference: `${fhirEnums.ResourceType.observation}/${result.id}`};

                    return this.saveAdministration(administration);
                });
            }
        } else {
            if (administration.resource.supportingInformation) {
                return this.deleteShiftReport(administration.resource.supportingInformation).then(() => {
                    delete administration.resource.supportingInformation;

                    return this.saveAdministration(administration);
                });
            }
        }

        return this.saveAdministration(administration);
    }

    saveAdministration(administration) {
        return this.fhirService.update(administration.resource).then((administrationResult) => {
            return {
                resource: administrationResult,
                status: administration.status,
                dose: administration.dose
            };
        });
    }

    deleteShiftReport(supportingInformation) {
        const [resourceType, id] = supportingInformation[0].reference.split('/');

        return this.fhirService.delete({
            id,
            resourceType
        });
    }

    close() {
        this.controller.cancel();
    }
}
