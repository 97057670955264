import { inject } from "aurelia-framework";
import { CarePlanService } from "../services/CarePlanService";
import { DialogController } from "aurelia-dialog";
import { I18N } from "aurelia-i18n";

@inject(CarePlanService, DialogController, I18N)
export class ModalDiagnosisList {
    carePlanService: CarePlanService;
    dialogController: DialogController;
    i18n: I18N;

    diagnosisCodeSystem;
    conditions;

    modalHeight = 800;

    diagnoseToTasksConceptMap;

    filter;

    items = [];
    
    activeMappings = []

    constructor(carePlanService: CarePlanService, dialogController: DialogController, i18n: I18N) {
        this.carePlanService = carePlanService;
        this.dialogController = dialogController;
        this.i18n = i18n;
    }

    async activate(model) {
        this.modalHeight = window.innerHeight * 0.7;

        this.activeMappings = model.activeMappings;
        this.filter = model.filter;
        this.diagnoseToTasksConceptMap = await this.carePlanService.getDiagnoseToTasksConceptMap(model.patient);
        this.diagnosisCodeSystem = JSON.parse(JSON.stringify(await this.carePlanService.getDiagnoseCodeSystem(model.patient)));

        this.diagnosisCodeSystem.concept.forEach((group) => {
            if (!group.concept) {
                return;
            }

            group.concept.forEach((category) => {
                if (!category.concept) {
                    return;
                }

                category.concept.forEach((subCat) => {
                    if (this.filter) {
                        if (this.findTasksIdsOfDiagnosis(subCat.code).includes(this.filter)) {
                            this.items.push({
                                group,
                                category,
                                subCat,
                                isExisting: this.activeMappings.find((mapping) => mapping.items.find((item) => item.diagnoseID === subCat.code)),
                                isSelected: false,
                                isDisabled: false
                            });
                        }
                    }
                });
            });
        });
    }

    cancel() {
        this.dialogController.cancel();
    }

    checkedItem(item, state) {
        for (let i = 0; i < this.items.length; i++) {
            if (!state) {
                this.items[i].isDisabled = false;
            } else {
                if (this.items[i].subCat.code !== item.subCat.code) {
                    this.items[i].isDisabled = true;
                }
            }
        }
    }

    submit() {
        if (this.isSubmitDisabled) {
            return;
        }

        this.dialogController.ok(this.items.filter((item) => item.isSelected).map((item) => ({
            code: item.subCat.code,
            display: item.subCat.display,
            definition: item.subCat.definition
        })));
    }

    get isSubmitDisabled() {
        return !this.items.find((item) => item.isSelected);
    }

    findTasksIdsOfDiagnosis(conditionCode) {
        const taskIds = CarePlanService.findConceptMapping(conditionCode, this.diagnoseToTasksConceptMap);

        return !taskIds ? [] : taskIds.map((task) => task.code);
    }
}
