import {autoinject} from 'aurelia-framework';
import {DialogController} from "aurelia-dialog";
import {UserService} from "../services/UserService";
import {I18N} from "aurelia-i18n";
import {ConfigService} from "../services/ConfigService";
import {translations} from "../classes/translations";

const moment = require("moment");

@autoinject
export default class ModalPlanningTimings {
    dialogController;
    userService;
    i18n;
    procedureRequest;
    timing;

    dpOptions = {
        format: 'HH:mm',
        locale: translations.language,
        showClear: false,
        showClose: true,
        widgetPositioning: {
            horizontal: 'left',
            vertical: 'auto'
        },
        focusOnShow: false,
        keepInvalid: false
    };

    ranges = [];
    times = [];
    timeslots = [];
    selectedTab = 1;

    weekDays = [
        'sun',
        'mon',
        'tue',
        'wed',
        'thu',
        'fri',
        'sat'
    ];

    get weekDaysDisplay() {
        const weekDays = this.weekDays.slice();

        weekDays.push(weekDays.shift());

        return weekDays;
    }

    constructor(dialogController: DialogController, userService: UserService, i18n: I18N) {
        this.dialogController = dialogController;
        this.userService = userService;
        this.i18n = i18n;
    }

    activate(model) {
        const careProcessConfig = ConfigService.GetFormSettings(ConfigService.FormNames.CareProcess);

        this.ranges = careProcessConfig && careProcessConfig.settings && careProcessConfig.settings.timeslots && careProcessConfig.settings.timeslots.length > 0 ? JSON.parse(JSON.stringify(careProcessConfig.settings.timeslots)) : [];
        this.procedureRequest = model.procedureRequest;
        this.timing = model.timing;

        const defaultRange = this.ranges.find((range) => range.default) || this.ranges[0];

        this.timeslots = defaultRange.slots.map((slot) => {
            let name = '';

            if (slot.title) {
                name += slot.title;
            }

            if (slot.subtitle) {
                name += ` (${slot.subtitle})`;
            }

            if (name === '') {
                const startMoment = moment().startOf('day');

                name = `${moment(startMoment).add(slot.time[0], 'hours').format('HH:mm')} - ${moment(startMoment).add(slot.time[1], 'hours').format('HH:mm')}`;
            }

            return {
                name,
                timeFrom: moment().startOf('day').add(slot.time[0], 'hours'),
                timeTo: moment().startOf('day').add(slot.time[1], 'hours')
            };
        });

        if (this.procedureRequest.occurrenceTiming.repeat && this.procedureRequest.occurrenceTiming.repeat.timeOfDay) {
            this.times = this.procedureRequest.occurrenceTiming.repeat.timeOfDay.map((tod, idx) => {
                const time = moment(tod, 'HH:mm');
                const timeslotIndex = this.timeslots.findIndex((timeslot) => time.isSameOrAfter(timeslot.timeFrom) && time.isBefore(timeslot.timeTo));
                const weekDay = this.procedureRequest.occurrenceTiming.repeat.dayOfWeek ? this.procedureRequest.occurrenceTiming.repeat.dayOfWeek[idx] : null;

                return {
                    m: time.toDate(),
                    v: null,
                    timeslot: timeslotIndex > -1 ? timeslotIndex : 0,
                    day: weekDay
                };
            });
        } else {
            const scale = this.getScaleForPerPeriod();

            if (!scale) {
                return;
            }

            for (let i = 0; i < scale.num; i++) {
                const stepHours = moment(scale.step).hours();
                const stepMinutes = moment(scale.step).minutes();
                const time = moment().startOf('day').add(stepHours, 'hours').add(stepMinutes, 'minutes');
                const timeslotIndex = this.timeslots.findIndex((timeslot) => time.isSameOrAfter(timeslot.timeFrom) && time.isBefore(timeslot.timeTo));

                this.times.push({
                    m: time.toDate(),
                    v: null,
                    timeslot: timeslotIndex > -1 ? timeslotIndex : 0,
                    day: this.weekDays[moment(scale.step).day()]
                });

                scale.step.add(scale.distance, 'minutes');
            }
        }
    }

    submit() {
        if (!this.procedureRequest.occurrenceTiming.repeat) {
            this.procedureRequest.occurrenceTiming.repeat = {};
        }

        this.procedureRequest.occurrenceTiming.repeat.timeOfDay = this.times.map((time) => moment(time.m).format('HH:mm'));

        if(this.timing.unit === 'wk') {
            this.procedureRequest.occurrenceTiming.repeat.dayOfWeek = this.times.map((time) => time.day);
        } else {
            this.procedureRequest.occurrenceTiming.repeat.dayOfWeek = null;
        }

        this.dialogController.ok(null);
    }

    cancel() {
        this.dialogController.cancel();
    }

    getScaleForPerPeriod() {
        const targetDate = moment();
        let totalLengthHours, step, num, distance;

        switch (this.timing.unit) {
            case 'wk': {
                if (this.timing.quantity == 1) {
                    distance = 0;
                } else if (this.timing.quantity == 2) {
                    distance = 24 * 3 * 60;
                } else if (this.timing.quantity == 3) {
                    distance = 24 * 2 * 60;
                } else if (this.timing.quantity >= 4 && this.timing.quantity <= 7) {
                    distance = 24 * 1 * 60;
                } else if (this.timing.quantity > 7 && this.timing.quantity <= 14) {
                    distance = 12 * 1 * 60;
                } else {
                    distance = 8 * 1 * 60;
                }

                step = moment(targetDate).startOf('week').add(7, 'hours');
                num = this.timing.quantity;
                break;
            }
            case 'd': {
                if (this.timing.quantity <= 6) {
                    totalLengthHours = 12;
                    step = moment(targetDate).startOf('day').add(7, 'hours');
                } else if (this.timing.quantity == 7) {
                    totalLengthHours = 14;
                    step = moment(targetDate).startOf('day').add(7, 'hours');
                } else if (this.timing.quantity == 8) {
                    totalLengthHours = 16;
                    step = moment(targetDate).startOf('day').add(6, 'hours');
                } else if (this.timing.quantity <= 20) {
                    totalLengthHours = 18;
                    step = moment(targetDate).startOf('day').add(6, 'hours');
                } else {
                    totalLengthHours = 18;
                    step = moment(targetDate).startOf('day').add(3, 'hours');
                }

                num = this.timing.quantity;
                distance = (totalLengthHours / this.timing.quantity) * 60;
                break;
            }
            case 'h': {
                totalLengthHours = 24;
                step = moment(targetDate).startOf('day');
                num = totalLengthHours * this.timing.quantity;
                distance = 60 / this.timing.quantity;
                break;
            }
            case 'min': {
                return;
            }
        }

        return {
            step,
            num,
            distance
        };
    }

    switchTime() {
        this.selectedTab = 0;
    }

    switchSlots() {
        this.times.forEach((t) => {
            const time = moment(t.m);
            const timeslotIndex = this.timeslots.findIndex((timeslot) => time.isSameOrAfter(timeslot.timeFrom) && time.isBefore(timeslot.timeTo));

            t.timeslot = timeslotIndex > -1 ? timeslotIndex : 0;
        });

        this.selectedTab = 1;
    }

    timeslotChanged(time) {
        const timeslot = this.timeslots[time.timeslot];
        const middleHour = Math.floor(timeslot.timeTo.diff(timeslot.timeFrom, 'hours') / 2);

        time.m = moment(timeslot.timeFrom).add(middleHour, 'hours').toDate();
    }
}
