import {inject} from 'aurelia-framework';
import {DialogController, DialogService} from 'aurelia-dialog';
import {ConfigService} from "../services/ConfigService";
import {FhirService} from "../services/FhirService";
import * as environment from "../../../config/environment.json";
import { UserService } from 'resources/services/UserService';

@inject(UserService, DialogController, DialogService, Element)
export class ModalOrm {
    userService: UserService;
    controller: DialogController;
    dialogService: DialogService;
    patient;
    iframeContainer;
    iframeUrl;
    iframeOrigin;
    iframeListener;
    element;
    tokenUpdateListenerRemove;
    type;
    group;
    serviceRequestReference;
    procedurePlannedTime;

    constructor(userService: UserService, dialogController: DialogController, dialogService: DialogService, element: Element) {
        this.userService = userService;
        this.controller = dialogController;
        this.dialogService = dialogService;
        this.element = element;

        this.iframeListener = (event) => {
            if (event.origin === this.iframeOrigin) {
                const data = event.data.data;
                switch (event.data.name) {
                    case 'save': {
                        this.controller.ok();
                        break;
                    }
                    case 'close': {
                        this.controller.cancel();
                        break;
                    }
                }
            }
        };
    }

    activate(params) {
        this.patient = params.patient;
        this.type = params.type;
        this.group = params.group;
        this.serviceRequestReference = params.serviceRequestReference;
        this.procedurePlannedTime = params.procedurePlannedTime;

        if (this.serviceRequestReference && typeof this.serviceRequestReference !== 'string') {
            throw new Error('Service Request must be a string of the form "ResourceType/ResourceId"');
        }
    }

    async attached() {
        const config = ConfigService.cfg.features.orm;
        const loginData = sessionStorage.getItem(environment.sessionName);

        if (!config) {
            console.error('ORM feature is not configured');
            return;
        }

        await this.userService.forceRefreshToken();
        this.tokenUpdateListenerRemove = this.userService.addTokenUpdateListener(this.sendNewToken.bind(this));
        let oauth: any;

        if (ConfigService.UseOAuth2) {
            ConfigService.EnsureTokens();

            oauth = JSON.stringify({
                access_token: ConfigService.AccessToken,
            });
        }

        if (this.patient?.encounterId) {
            const query:any = {
                login: loginData,
                oauth,
                server: FhirService.Endpoint,
                origin: window.location.origin,
                encounterId: this.patient.encounterId,
                group: this.group,
            };

            if (this.serviceRequestReference) {
                query.edit = this.serviceRequestReference;
            }

            if (this.procedurePlannedTime) {
                query.procedurePlannedTime = this.procedurePlannedTime;
            }

            if (this.userService.practitioner) {
                query.practitionerId = this.userService.practitioner.id;
            }

            this.iframeUrl = `${config.interventionsUrl}?` + Object.keys(query).map((key) => {
                return `${key}=${encodeURIComponent(query[key])}`;
            }).join('&');

            this.iframeOrigin = this.iframeUrl ? this.iframeUrl.match(/^https?\:\/\/([^\/?#]+)/i)[0] : '';
        }
        
        window.addEventListener('message', this.iframeListener);
    }

    detached() {
        this.tokenUpdateListenerRemove();

        window.removeEventListener('message', this.iframeListener);
    }

    sendNewToken() {
        if (this.iframeContainer) {
            try {
                this.iframeContainer.contentWindow.postMessage({
                    name: 'update-token',
                    data: sessionStorage.getItem(environment.oauth2SessionName)
                }, this.iframeUrl);
            } catch (e) {
            }
        }
    }

    close() {
        this.controller.cancel();
    }
}
