import {inject} from 'aurelia-framework';
import {DialogController, DialogService} from 'aurelia-dialog';

const moment = require("moment");
import {FhirService} from "../services/FhirService";

@inject(Element, DialogController, DialogService, FhirService)
export class ModalShiftReportHistory {
    element;
    dialogService;
    controller;
    fhirService: FhirService;
    report;
    history;
    practitioners: any[] = [];

    constructor(element, dialogController, dialogService, fhirService: FhirService) {
        this.element = element;
        this.dialogService = dialogService;
        this.controller = dialogController;
        this.fhirService = fhirService;

        this.controller.settings.centerHorizontalOnly = true;
    }

    activate(params) {
        this.report = params.report;
    }

    async attached() {
        this.element.style.maxWidth = "960px";
        this.element.style.width = "80%";

        let observations: any[] = await this.fhirService.fetch(`Observation/${this.report.id}/_history`, true);
        this.history = [];
        if (!observations) observations = [];
        for (const o of observations) {
            this.history.push(await this.parseReport(o));
        }
    }

    async parseReport(data: any) {
        let performer: any = undefined;
        let performerName: string = data.comment;

        if (data.performer) {
            let perfRef: any = data.performer.find(o => o.reference && o.reference.indexOf("Practitioner/") > -1);
            if (perfRef) {
                let id = perfRef.reference.split('/')[1];
                performer = this.practitioners.find(o => o.id === id);

                if (!performer) {
                    try {
                        performer = <any>await this.fhirService.get(perfRef.reference);
                        this.practitioners.push(performer);
                    } catch (e) {
                        console.warn(e);
                    }
                }
            }
        }

        if (performer) {
            if (performer.name && performer.name.length > 0) {
                let official = performer.name.find(o => o.use === "official") || performer.name[0];
                if (official.family) {
                    performerName = [official.family, official.given ? official.given?.join(' ') : ''].join(', ').trim();
                }
            }
        }

        return {
            id: data.id,
            datetime: moment(data.effectiveDateTime).toDate(),
            shift: this.findComponent('shift', data),
            markSupplement: this.findComponent('mark-supplement', data, true),
            nursingTransfer: this.findComponent('nursing-transfer', data, true),
            additionalInfo: this.findComponent('additional-info', data, true),
            status: data.status,
            reportText: data.valueString,
            user: performerName,
            lastUpdated: data.meta.lastUpdated
        };
    }

    findComponent(name, data, isBoolean = false) {
        const val = data.component ? data.component.find((cmp) => cmp.code.text === name) : null;

        if (val) {
            return isBoolean ? val.valueString === 'true' : val.valueString;
        } else {
            return isBoolean ? false : null;
        }
    }

    submit() {
    }

    close() {
        this.controller.close();
    }
}

