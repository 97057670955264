import {inject, observable, TaskQueue} from 'aurelia-framework';
import {DialogController, DialogService} from "aurelia-dialog";
import {Prompt} from "./prompt";

const moment = require("moment");
import {I18N} from "aurelia-i18n";
import {BodymapService} from "../services/BodymapService";
import {RuntimeInfo} from "../classes/RuntimeInfo";
import {PatientItem} from "../classes/Patient/PatientItem";

@inject(Element, DialogController, DialogService, TaskQueue, I18N, BodymapService)
export class ModalBodyMapAdd {
    element: HTMLElement;

    controller: DialogController;

    dialogService: DialogService;

    taskQueue: TaskQueue;

    bodymapService: BodymapService;

    patient;

    pickerDateOnly: boolean = false;

    @observable pickerValueDateOnly: string;

    @observable pickerValueFull: string;

    dateObject: Date = null;

    currentDate: string;

    editMode = false;

    editData = null;

    location: string;

    locationLocalized: string;

    dateObjectHealed;

    info: string;

    @observable healed: boolean;

    @observable pickerHealed;

    @observable dateElementFull;

    @observable dateElementDateOnly;

    @observable dateElementHealed;

    dpOptions = {
        format: RuntimeInfo.DateTimeFormat,
        locale: RuntimeInfo.Language,
        showTodayButton: true,
        showClear: false,
        showClose: true,
        keepOpen: false,
        widgetPositioning: {
            horizontal: 'left',
            vertical: 'auto'
        },
        focusOnShow: false
    };

    dpOptionsDateOnly = {
        format: 'DD.MM.YYYY',
        locale: RuntimeInfo.Language,
        showTodayButton: true,
        showClear: false,
        showClose: true,
        keepOpen: false,
        widgetPositioning: {
            horizontal: 'left',
            vertical: 'auto'
        },
        focusOnShow: false
    };

    types: IType[] = [];

    formValid = false;

    selectedType1 = -1;
    selectedType2 = -1;
    selectedType3 = -1;

    enabled = [];
    disabled = [];

    maxDate;
    i18n: I18N;

    timeType = 'time-point';

    timeframe = '-1';

    availableAtAdmission = false;

    constructor(element, dialogController, dialogService, taskQueue, i18n: I18N, bodymapService) {
        this.element = element;
        this.controller = dialogController;
        this.dialogService = dialogService;
        this.taskQueue = taskQueue;
        this.i18n = i18n;
        this.bodymapService = bodymapService;

        dialogController.settings.centerHorizontalOnly = true;
    }

    async attached() {
        this.element.style.maxWidth = "950px";
        this.element.style.width = "80%";

        if (this.editData) {
            this.selectedType1 = this.types.findIndex((type) => type.name == this.editData.type1);

            if (this.types[this.selectedType1].subtypes) {
                this.selectedType2 = this.types[this.selectedType1].subtypes.findIndex((type) => type.name == this.editData.type2);

                if (this.types[this.selectedType1].subtypes) {
                    let subtypes = this.types[this.selectedType1].subtypes;

                    if (subtypes[this.selectedType2] && subtypes[this.selectedType2].items) {
                        let items = this.types[this.selectedType1].subtypes[this.selectedType2].items;
                        this.selectedType3 = items.findIndex((type) => type == this.editData.type3);
                    }
                }
            }

            this.pickerDateOnly = this.types[this.selectedType1].dateOnly;

            if (this.editData.date) {
                this.dateObject = new Date(this.editData.date);
            }

            this.currentDate = moment(this.editData.currentDate).format('DD.MM.YYYY HH:mm');
            this.location = this.editData.location;
            this.info = this.editData.info;

            if (this.editData.healDate) {
                this.healed = true;
                this.dateObjectHealed = new Date(this.editData.healDate);
            }

            this.formValid = true;

            this.timeType = this.editData.timeType || 'time-point';
            this.timeframe = this.editData.timeframe ? String(this.editData.timeframe) : '-1';

            this.availableAtAdmission = this.editData.availableAtAdmission;
        } else {
            this.currentDate = moment().format('DD.MM.YYYY HH:mm');

            this.checkAvailableAtAdmission();
        }
    }

    async activate(params) {
        await this.createData();

        this.patient = params.patient;
        this.maxDate = params.maxDate || moment().add(10, 'years').toDate();

        if (params.editMode && params.data) {
            this.editMode = params.editMode;
            this.editData = params.data;

            if (params.location) {
                if (Array.isArray(params.location)) {
                    this.locationLocalized = params.location.map((lcn) => this.i18n.tr(lcn)).join(', ');
                } else {
                    this.locationLocalized = this.i18n.tr(params.location);
                }
            } else if (this.editData.location) {
                if (Array.isArray(this.editData.location)) {
                    this.locationLocalized = this.editData.location.map((lcn) => this.i18n.tr(lcn)).join(', ');
                } else {
                    this.locationLocalized = this.i18n.tr(this.editData.location);
                }
            }
        } else if (params.location) {
            this.enabled = params.enabled || [];
            this.disabled = params.disabled || [];
            this.location = params.location;

            if (this.enabled.length === 1) {
                this.selectedType1 = this.types.findIndex((type) => type.name == this.enabled[0]);
            }

            if (this.location) {
                if (Array.isArray(this.location)) {
                    this.locationLocalized = this.location.map((lcn) => this.i18n.tr(lcn)).join(', ');
                } else {
                    this.locationLocalized = this.i18n.tr(this.location);
                }
            }
        }
    }

    async createData() {
        const wounds = await this.bodymapService.getWounds();

        wounds.forEach((wound) => {
            const typeObject: IType = {
                name: wound.name,
                icon: wound.icon,
                dateOnly: wound.dateOnly,
                healedText: wound.healedText ? this.i18n.tr(wound.healedText) : this.i18n.tr('healed')
            };

            if (wound.subtypes) {
                typeObject.subtypes = wound.subtypes.map((subtype) => {
                    const subtypeObject: ISubtype = {
                        name: subtype.name
                    };

                    if (subtype.items) {
                        subtypeObject.items = subtype.items.slice();
                    }

                    return subtypeObject;
                });
            }

            this.types.push(typeObject);
        });
    }

    type1Changed(idx) {
        if (this.editMode) return;

        this.pickerDateOnly = this.types[idx].dateOnly;

        this.selectedType1 = idx;
        this.selectedType2 = -1;
        this.selectedType3 = -1;

        this.checkFormValid();
    }

    type2Changed(idx) {
        this.selectedType2 = idx;
        this.selectedType3 = -1;

        this.checkFormValid();
    }

    type3Changed(idx) {
        this.selectedType3 = idx;

        this.checkFormValid();
    }

    pickerValueDateOnlyChanged() {
        this.checkFormValid();
        this.checkAvailableAtAdmission();
    }

    pickerValueFullChanged() {
        this.checkFormValid();
        this.checkAvailableAtAdmission();
    }

    healedChanged() {
        this.checkFormValid();
    }

    pickerHealedChanged() {
        this.checkFormValid();
    }

    checkFormValid() {
        let formValid = true;

        if (this.selectedType1 === -1) {
            this.formValid = false;
            return;
        }

        if (this.timeType == 'time-point') {
            if (this.types[this.selectedType1].dateOnly) {
                if (!this.pickerValueDateOnly) {
                    formValid = false;
                }
            } else {
                if (!this.pickerValueFull) {
                    formValid = false;
                }
            }
        }

        if (this.types[this.selectedType1].subtypes) {
            if (this.selectedType2 === -1) {
                formValid = false;
            } else {
                if (this.types[this.selectedType1].subtypes[this.selectedType2].items) {
                    if (this.selectedType3 === -1) {
                        formValid = false;
                    }
                }
            }
        }

        if (this.healed && !this.pickerHealed) {
            formValid = false;
        }

        this.formValid = formValid;
    }

    dateElementFullChanged() {
        let date = new Date();
        if (this.maxDate) {
            date = this.maxDate.toDate ? this.maxDate.toDate() : new Date(this.maxDate);
        }

        this.dateElementFull.methods.maxDate(date);
    }

    dateElementDateOnlyChanged() {
        let date = new Date();
        if (this.maxDate) {
            date = this.maxDate.toDate ? this.maxDate.toDate() : new Date(this.maxDate);
        }

        this.dateElementDateOnly.methods.maxDate(date);
    }

    dateElementHealedChanged() {
        let date = new Date();
        if (this.maxDate) {
            date = this.maxDate.toDate ? this.maxDate.toDate() : new Date(this.maxDate);
        }

        if (this.dateObject) {
            this.dateElementHealed.methods.minDate(moment(this.dateObject).toDate());
        }

        this.dateElementHealed.methods.maxDate(date);
    }

    submitForm() {
        if (!this.formValid) {
            return;
        }

        const data: any = {
            type1: this.types[this.selectedType1].name,
            date: this.dateObject,
            timeType: this.timeType,
            timeframe: this.timeframe,
            currentDate: moment(this.currentDate, 'DD.MM.YYYY HH:mm').toDate(),
            location: this.location,
            info: this.info,
            healDate: this.healed ? this.dateObjectHealed : null,
            availableAtAdmission: this.availableAtAdmission
        };

        if (this.timeType == 'time-frame') {
            data.date = this.patient.encounter.period ? moment(this.patient.encounter.period.start).toDate() : this.dateObject;
        }

        if (this.selectedType2 > -1) {
            data.type2 = this.types[this.selectedType1].subtypes[this.selectedType2].name;
        }

        if (this.selectedType3 > -1) {
            data.type3 = this.types[this.selectedType1].subtypes[this.selectedType2].items[this.selectedType3];
        }

        this.controller.ok(data);
    }

    deleteItem() {
        this.dialogService.open({
            viewModel: Prompt, model: {message: this.i18n.tr("confirm")}
        }).whenClosed((result) => {
            if (!result.wasCancelled) {
                this.controller.ok();
            }
        });
    }

    setTimeType(type) {
        this.timeType = type;

        this.checkAvailableAtAdmission();
        this.checkFormValid();
    }

    checkAvailableAtAdmission() {
        if (this.editMode)
            return;

        if (!this.patient)
            this.patient = PatientItem.SelectedPatient || PatientItem.LastLoadedPatient;

        this.availableAtAdmission = this.timeType == 'time-point' && this.patient.encounter.period && moment(this.dateObject).isBefore(moment(this.patient.encounter.period.start).startOf('day'));
    }
}

interface IType {
    name: string,
    icon: string,
    dateOnly?: boolean,
    subtypes?: ISubtype[],
    healedText?: string
}

interface ISubtype {
    name: string,
    items?: string[]
}
